@tailwind base;

@layer base {
    .no-tailwindcss-base h1,
    .no-tailwindcss-base h2,
    .no-tailwindcss-base h3,
    .no-tailwindcss-base h4,
    .no-tailwindcss-base h5,
    .no-tailwindcss-base h6 {
        font-size: revert;
        font-weight: revert;
    }

    .no-tailwindcss-base ol,
    .no-tailwindcss-base ul {
        list-style: revert;
        margin: revert;
        padding: revert;
    }
}
@layer components {
    .no-tailwindcss-base a {
        @apply text-navyBlue underline;
    }
}
@tailwind components;
@tailwind utilities;

